import styled from "styled-components";
import userSign from '../../../static/images/artwork/userSign.svg';
import userClap from '../../../static/images/artwork/userClap.svg';
import userMagnifying from '../../../static/images/artwork/userMagnifying.svg';
import userNotebook from '../../../static/images/artwork/userNotebook.svg';
import userSofa from '../../../static/images/artwork/userSofa.svg';
import userValidate from '../../../static/images/artwork/userValidate.svg';

const CardContainer = styled.div`
    --gridColumnCount: 3;
    --gridRowGap: 60px;
    --gridColumnGap: 10px;
    display: grid;
    grid: auto/repeat(var(--gridColumnCount),1fr);

    justify-items: center;
    align-items: center;
    row-gap: var(--gridRowGap);
    column-gap: var(--gridColumnGap);
    width: 100%;
    max-width: 1400px;
    padding: 10px;

    @media (max-width: 950px) {
        --gridColumnCount: 2;
    }

    @media (max-width: 560px) {
        --gridColumnCount: 1;
        --gridRowGap: 40px;
    }
`;

const CenteredFlex = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;


const InfoCard = styled.div`
    max-width: 370px;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;

    .header{
        background-color: ${props => `${props.borderColor}`};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 69px;
    }

    .card-content{
        padding: 16px;
        min-height: 115px;
        display: flex;
        align-items: center;
    }


    h3{
        font-family: "GilroyBold";
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        color: #1a273d;    
        margin-bottom: 0;
    }

    p{
        font-family: 'GilroyMedium';
        opacity: 0.75;
        font-size: 18px;
        font-weight: 500;
        color: #1a273d;
        margin-bottom: 0;
    }

    .header-picto{
        margin-top: 30px;
    }
`;


export default function AboutCards() {
    return (
        <CenteredFlex>
            <CardContainer>
                <InfoCard borderColor="#E8EDFF">
                    <div className="header">
                        <h3>Connaitre</h3>
                        <img src={userSign} className='header-picto' width="63px" height='66px' alt="user sign" />
                    </div>
                    <div className="card-content">
                        <p>Avoir la liste de tout ce que vous devez fournir comme documents.</p>
                    </div>
                </InfoCard>

                <InfoCard borderColor="#E4F8F3">
                    <div className="header">
                        <h3>Récupérer</h3>
                        <img src={userMagnifying} className='header-picto' width="52px" height='70px' alt="user magnifying" />
                    </div>
                    <div className="card-content">
                        <p>Laisser notre robot chercher les documents de votre copropriété.</p>
                    </div>
                </InfoCard>

                <InfoCard borderColor="#FCF3EA">
                    <div className="header">
                        <h3>Pré-état daté</h3>
                        <img src={userNotebook} className='header-picto' width="53px" height='70px' alt="user notebook" style={{marginTop:'50px'}} />
                    </div>
                    <div className="card-content">
                        <p>Recevoir un pré-état daté pour 60€ seulement et livré en 24h.</p>
                    </div>
                </InfoCard>

                <InfoCard borderColor="#FCEEF9">
                    <div className="header">
                        <h3>Collaborer</h3>
                        <img src={userClap} className='header-picto' width="55px" height='74px' alt="user clap" />
                    </div>
                    <div className="card-content">
                        <p>Partager les documents avec le notaire, les acquéreurs, les agents immobiliers ou les vendeurs.</p>
                    </div>
                </InfoCard>

                <InfoCard borderColor="#E8ECF7">
                    <div className="header">
                        <h3>Valider</h3>
                        <img src={userValidate} className='header-picto' width="55px" height='77px' alt="user validate" />
                    </div>
                    <div className="card-content">
                        <p>Avoir un contrôle de tous les documents pour garantir la signature du compromis.</p>
                    </div>
                </InfoCard>
                <InfoCard borderColor="#F4E5FA">
                    <div className="header">
                        <h3>Commander</h3>
                        <img src={userSofa} className='header-picto' width="100px" height='60px' alt="user sofa" />
                    </div>
                    <div className="card-content">
                        <p>Bénéficier de réductions pour vos achats (titre de propriété, diagnostics ou d'un état hypothécaire).</p>
                    </div>
                </InfoCard>
            </CardContainer>
        </CenteredFlex>
    );
}